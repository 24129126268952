import React from "react";
import BenefitRows from "./BenefitRows";
import IndustryStripeSmall from "components/common/IndustryStripeSmall";
import InfoSlider from "components/common/InfoSlider";
import IntegrationSlider from "components/common/IntegrationSlider";
import TestimonySlider from "components/common/TestimonySlider";
import DemoFormSection from "components/common/DemoFormSection";
import HeroLayout1 from "components/common/hero/HeroLayout1";

const ITPageTemplate = ({ data }) => {
  return (
    <>
      <HeroLayout1 data={data.headerSection} right />
      <IndustryStripeSmall logos={data.logos} theme="dark" />
      <BenefitRows data={data.benefitRows} />
      <InfoSlider
        data={data.infoSlider.slides}
        color={"var(--nuetral-100)"}
        title="Feature-Rich Security Platform for IT Teams"
      />
      <IntegrationSlider />
      <TestimonySlider
        data={data.customerReviews}
        title="See Why Industry Leaders Choose Rhombus"
        subTitle="From a strong sense of partnership to a great product, industry leaders everyday are choosing to work with Rhombus."
        color="var(--white)"
      />
      <DemoFormSection data={data.formSection} />
    </>
  );
};

export default ITPageTemplate;
